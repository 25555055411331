<template>
  <div class="News">
    <div class="banner">

    </div>
    <div class="about pd40">
      <div class="container">
        <div class="headTit">
          <h3>About <span>ZHUXUE</span></h3>
        </div>
        <div class="aboutCon">
          <div class="left">
            <h3>
              We aim to serve International Students with all our effort<br>
              Provide the most valuable information for students studying abroad

            </h3>
            <p>ZHUXUE began its operation in 2006, so far it has had 15 years experience in international education field. In response to the increasing demand of international students to study in China, ZHUXUE has gradually developed a network that provides a convenient way for international students to search useful information and get direct contact with University Admissions. ZHUXUE network has critical online resources needed and connected international students all over the world. Our goal is to help these students make their study dreams coming true, broaden their international mindset and global vision, enhance their understanding of education and increase bilateral exchanges in all aspects. We hope to help students in every conceivable way to make them succeed in international education.</p>
          </div>
          <div class="imgbox">
            <img src="../assets/about2.png"
                 alt="">
            <div class="_abo">
              <router-link to="/scholarship"
                           class="lis">
                <i class="imgbox"><img src="@/assets/sico1.png"
                       alt=""></i>
                <div>
                  <p>Scholarship</p>
                </div>
              </router-link>
              <router-link to="popular-major"
                           class="lis">
                <i class="imgbox"><img src="@/assets/sico2.png"
                       alt=""></i>
                <div>
                  <p>University search</p>
                </div>
              </router-link>
              <a href="javascript:;"
                 @click="onShowVioForm()"
                 class="lis">
                <i class="imgbox"><img src="@/assets/sico3.png"
                       alt=""></i>
                <div>
                  <p>Consulting service</p>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="imgbox">
          <img src="@/assets/about111.png"
               alt="">
        </div>
      </div>
    </div>
    <div class="chooseUni pd40">
      <div class="container">
        <div class="headTit">
          <h3>University Search</h3>
        </div>
        <div class="searchModel">
          <div class="mods"
               :class="searchModNum == 0 ? 'on':''"
               @click="searchModNum = 0">
            Search by University
          </div>
          <div class="mods"
               :class="searchModNum == 1 ? 'on':''"
               @click="searchModNum = 1">
            Search by specialty
          </div>
        </div>
        <div class="searchBar">
          <div class="item">
            <el-input v-if="searchModNum == 0"
                      placeholder="Enter the university keyword"
                      v-model="inputVal"
                             @keyup.enter="onSearch"
                      clearable></el-input>
            <el-autocomplete v-if="searchModNum == 1"
                             v-model="inputVal"
                             :fetch-suggestions="querySearch"
                             :trigger-on-focus="false"
                             class="inline-input"
                             placeholder="Please enter the full name of your major"
                             @keyup.enter="onSearch"
                             @select="handleSelect" />
          </div>
          <div class="item">
            <el-button type="danger"
                       @click="onSearch"> <i class="el-icon-search"></i> Search</el-button>
          </div>
        </div>
      </div>
    </div>
    <ComForm ref='vioForm'
             :typeId='7'
             formTitle="Consulting service"></ComForm>
  </div>
</template>

<script>
import axiosApi from "../api/api.js";


import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'

import ComForm from "@/components/ComForm.vue";

export default {
  components: {
    ComForm
  },
  data () {
    return {
    }
  },
  setup () {
    const inputVal = ref('')
    const opErrorMsg = (val) => {
      ElMessage.error(val)
    }
    const router = useRouter()
    const searchModNum = ref(0)
    const onSearch = () => {
      if (inputVal.value == '') {
        opErrorMsg('The content cannot be empty !!!')
      } else {
        router.push({ path: '/popular-major', query: { search: inputVal.value, searchModNum: searchModNum.value } })
      }
    }

    const vioForm = ref()
    const onShowVioForm = () => {
      vioForm.value.onShowVio()
    }




    const restaurants = ref([])
    const querySearch = (queryString, cb) => {
      const results = queryString
        ? restaurants.value.filter(createFilter(queryString))
        : restaurants.value
      // call callback function to return suggestions
      cb(results)
    }
    const createFilter = (queryString) => {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        )
      }
    }

    const handleSelect = (item) => {
      console.log(item)
    }


    onMounted(() => {
      axiosApi(
        'university/vocational', {
      }, 'get'
      ).then((res) => {
        let arr = []
        // this.zhongzhuan = markRaw(res.data)
        res.data.forEach((item) => {
          let doctoral = item.doctoral ? item.doctoral : ''
          if (doctoral.length > 0) {
            doctoral.forEach((item) => {
              item.father = 'doctoral'
            })
          }
          let undergraduate = item.undergraduate ? item.undergraduate : ''
          if (undergraduate.length > 0) {
            undergraduate.forEach((item) => {
              item.father = 'undergraduate'
            })
          }
          let masters_degree = item.masters_degree ? item.masters_degree : ''
          if (masters_degree.length > 0) {
            masters_degree.forEach((item) => {
              item.father = 'masters_degree'
            })
          }
          arr = [...arr, ...doctoral, ...undergraduate, ...masters_degree]
        })
        let deWeightThree = () => {
          let map = new Map();
          for (let item of arr) {
            if (!map.has(item.program)) {
              map.set(item.program, item);
            }
          }
          return [...map.values()];
        }
        let nArr = deWeightThree()
        let nMajor = []
        nArr.forEach((item) => {
          nMajor = [...nMajor, {
            value: item.program,
            label: item.program,
            link: item.program,
            fa: item.father
          }]
        })
        restaurants.value = nMajor
      })
    })


    return {
      inputVal,
      onSearch,
      onShowVioForm,
      vioForm,
      searchModNum,
      restaurants,
      state1: ref(''),
      state2: ref(''),
      querySearch,
      createFilter,
      handleSelect,
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  background: url(~@/assets/neibanner1.jpg) top center no-repeat;
  height: 400px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    .title {
      font-size: 38px;
      font-weight: bold;
      color: #fff;
      letter-spacing: 4px;
    }
  }
}
.about {
  .aboutCon {
    display: flex;
    .left {
      width: 50%;
      h3 {
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 20px;
      }
      p {
        color: #616161;
        margin-bottom: 10px;
        line-height: 1.7;
      }

      .list {
        margin-top: 15px;
        .con {
          box-shadow: 0px 0px 8px rgba($color: #000000, $alpha: 0.1);
          padding: 10px;
          border-radius: 8px;
          transition: ease 0.5s;
          &:hover {
            background-color: #ffc107;
            .ico {
              transform: rotateY(360deg);
            }
          }
        }
        .ico {
          width: 60px;
          height: 60px;
          text-align: center;
          margin: auto;
          line-height: 60px;
          color: #004168;
          transition: ease 1s;
          i {
            font-size: 48px;
          }
        }
        h3 {
          font-size: 16px;
          font-weight: normal;
          margin-bottom: 0;
          margin-top: 10px;
          text-align: center;
        }
      }
    }
    .imgbox {
      width: 50%;
      padding-left: 20px;
      position: relative;
      ._abo {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transform: translate(30px, -30px);
        color: #fff;
        .lis {
          width: 300px;
          padding-left: 30px;
          position: relative;
          height: 100px;
          display: flex;
          align-items: center;
          .imgbox {
            width: 55px;
            height: 55px;
            padding-left: 0;
            transition: ease 0.5s;
            margin-right: 15px;
          }
          &::before {
            position: absolute;
            content: "";
            width: 10px;
            height: 10px;
            left: 0;
            top: 44px;
            border-radius: 50%;
            border: 2px solid #fff;
          }
          &::after {
            position: absolute;
            content: "";
            width: 2px;
            height: 90px;
            left: 6px;
            top: 56px;
            background-color: #fff;
          }
          &:last-child::after {
            display: none;
          }
          &:hover {
            .imgbox {
              transform: rotateY(180deg);
            }
          }
        }
      }
    }
  }
}
.chooseUni {
  padding: 60px;
  background: url(~@/assets/cho.png) center center no-repeat;
  .headTit {
    color: #fff;
    p {
      color: #fff;
    }
  }
  .searchBar {
    display: flex;
    border-radius: 8px;
    margin: auto;
    margin-top: 20px;
    box-shadow: 0px 0px 8px rgba($color: #000000, $alpha: 0.2);
    background-color: rgb(56, 56, 56);
    margin-bottom: 40px;
    max-width: 800px;
    overflow: hidden;
    background-color: #fff;
    a {
      display: block;
      line-height: 40px;
      text-align: center;
      width: 100px;
      color: #0c3879;
      font-weight: bold;
    }
    .el-select ::v-deep {
      .el-input__inner {
        border: none;
        width: 200px;
        padding-left: 36px;
        --el-input-placeholder-color: #999;
        color: #333;
      }
    }
    .el-input ::v-deep {
      .el-input__inner {
        border: none;
        width: 600px;
        padding-left: 36px;
        background: url(~@/assets/ico4.png) 10px center no-repeat;
        background-size: 16px;
        --el-input-placeholder-color: #999;
        color: #333;
      }
    }
    .el-button {
      width: 200px;
    }
  }
}

.searchModel {
  display: flex;
  max-width: 800px;
  margin: 0 auto;
  .mods {
    padding: 2px 10px;
    color: #fff;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 7px;
    &.on,
    &:hover {
      background-color: #fff;
      color: #004168;
    }
  }
}
</style>


<style lang="scss">
.el-autocomplete {
  .el-input__inner {
    border: none;
    width: 600px;
    padding-left: 36px;
    background: url(~@/assets/ico4.png) 10px center no-repeat;
    background-size: 16px;
    --el-input-placeholder-color: #999;
    color: #333;
  }
}
</style>